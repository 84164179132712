<template>
  <div>
    <v-dialog v-model="ModalEdit" persistent max-width="80%">
      <v-card>
        <div v-if="refpelaksana.length == 0">
          <v-skeleton-loader type="card, article"></v-skeleton-loader>
        </div>

        <div v-else>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Pilih Atasan
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Lengkapi Data
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <div class="px-5 pt-5 mb-n3">
                <h3>EDIT DATA JABATAN</h3>
              </div>

              <v-stepper-content step="1">
                <v-card class="mb-5" flat>
                  <organization-chart
                    :datasource="dataset"
                    @node-click="(nodeData) => pilihatasan(nodeData)"
                  >
                    <template slot-scope="{ nodeData }">
                      <v-card class="px-2" outlined>
                        <small>
                          {{ nodeData.title }}
                        </small>
                      </v-card>
                    </template>
                  </organization-chart>
                </v-card>

                <v-btn text @click="closeModal()"> Batal </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-5 py-3" flat>
                  <v-text-field
                    v-model="unor_name"
                    label="Unit"
                    outlined
                    dense
                    readonly
                    filled
                  ></v-text-field>

                  <v-text-field
                    v-model="atasan.title"
                    label="Nama Atasan"
                    outlined
                    dense
                    readonly
                    filled
                  ></v-text-field>

                  <v-row dense>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="SData.namaJabatan"
                        label="Nama Jabatan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="SData.janis_jabatan"
                        :items="refjabatan"
                        label="Jenis Jabatan"
                        item-value="id"
                        item-text="nama"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="SData.eselon"
                        :items="refeselon"
                        label="Eselon"
                        item-value="id"
                        item-text="nama"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="SData.pelaksana"
                        :items="refpelaksana"
                        label="Pelaksana"
                        item-value="id"
                        item-text="nama"
                        hide-no-data
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="SData.fungsional"
                        :items="reffungsional"
                        label="Fungsional"
                        item-value="id"
                        item-text="nama"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="SData.kebutuhan"
                        label="Kebutuhan"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row>
                  <v-col cols="10">
                    <v-btn color="primary" class="mr-2" @click="e1 = 1">
                      <v-icon left> mdi-arrow-left </v-icon>
                      Kembali
                    </v-btn>

                    <v-btn text @click="closeModal()"> Batal </v-btn>
                  </v-col>

                  <v-col cols="2" class="text-right">
                    <v-btn color="success" @click="edit()" v-if="LBtn">
                      <v-icon left> mdi-content-save </v-icon>
                      Simpan
                    </v-btn>

                    <v-btn color="success" loading v-else>
                      <v-icon left> mdi-content-save </v-icon>
                      Simpan
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";
import OrganizationChart from "vue-organization-chart";

import SView from "@/store/anjab/petajabatan/view";
import SEdit from "@/store/anjab/petajabatan/edit";

export default {
  components: {
    OrganizationChart,
  },

  computed: {
    ModalEdit: {
      get() {
        return SEdit.state.ModalEdit;
      },
      set(value) {
        SEdit.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SEdit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },

    sjj() {
      if (!this.seljj.length) {
        console.log(this.SData);
        return "kosong";
      } else {
        let data = {
          id: this.seljj[0].id,
          nama: this.seljj[0].nama,
          atasan: this.seljj[0].atasan,
          kelompok: this.seljj[0].kelompk,
        };
        return data;
      }
    },
  },

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refjj = await getRef.new_jj(this.token);
        this.refjabatan = await getRef.jJabatan(this.token);
        this.refeselon = await getRef.eselon(this.token);
        this.reffungsional = await getRef.fungsional(this.token);
        this.refpelaksana = await getRef.new_pelaksana(this.token);
        this.getData();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),
    LBtn: true,

    e1: 1,

    refjabatan: [],
    refeselon: [],
    refpelaksana: [],
    reffungsional: [],

    refjj: [],
    seljj: [],

    dataset: {},

    atasan: "",
  }),

  methods: {
    reset() {
      this.refjabatan = [];
      this.refeselon = [];
      this.refpelaksana = [];
      this.reffungsional = [];

      this.dataset = {};

      this.atasan = "";
    },

    convert(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "namaJabatan") {
          this.title = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else {
          return v;
        }
      });

      return parsed;
    },

    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "anjab/all";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.dataset = this.convert(res.data.mapData[0]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    pilihatasan(value) {
      this.atasan = value;
      this.e1 = 2;
    },

    async edit() {
      this.LBtn = false;
      let data = {
        idJab: this.$route.params.id,
        parent: this.atasan.id,
        namaJabatan: this.SData.namaJabatan,
        unor: {
          id: this.SData.unor.id,
        },
        janis_jabatan: {
          id: this.SData.janis_jabatan.id,
        },
        eselon: {
          id: this.SData.eselon.id,
        },
        pelaksana: {
          id: this.SData.pelaksana.id,
        },
        fungsional: {
          id: this.SData.fungsional.id,
        },
        kebutuhan: this.SData.kebutuhan,
      };

      const url = process.env.VUE_APP_API + "anjab/edit";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
            this.closeModal();
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
            this.closeModal();
          }
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.e1 = 1;
      this.ModalEdit = false;
    },
  },
};
</script>
